@use "../../Scss-vars/Colors.scss";

.NavBar {
 z-index: 100;
  .row {
    transition: Colors.$transition;
    .nav-link:focus {
      color: #fff;
    }
 
    .header-links {
      color: Colors.$mainText;
      transition: Colors.$transition;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      border-radius: 5px;
      padding-left: 20px !important;
      padding-right: 20px !important;
      &:hover {
        color: Colors.$white;
        background-color: Colors.$mainColor;
      }
    }
    .header-links-active {
      color: Colors.$white !important;
      background-color: Colors.$mainColor;
      &:hover {
        color: Colors.$white;
        background-color: Colors.$mainColor;
      }
    }
    .btn-icon{
        border: 1px solid #f0eaea;
        color:#757474;
        outline: none;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        transition: Colors.$transition;
        
        &:hover{
            color: Colors.$mainText;
        }
    }
    .dropdowen{
      background:rgba(255,255,255, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
     margin-top: 15px;
     z-index: 200;
    }
    .sideNav{
      height: 0px;
      padding: 0 !important;
      overflow: hidden;
      transition: Colors.$transition;
    }
    .sideNav-height{
      height: 192px;
      transition: Colors.$transition;
      overflow: hidden;
    }
  
  }
}
.navBar-white{
  background:rgba(255,255,255, 0.8) !important;
}
.bg-for-home{
  background-color: #141414;
  a{
    color: #ffffff !important;
  }
  .sideNav-for-Home a{
    color: #141414 !important;
    &:hover{
      color: #ffffff !important;
    }
  }
}