@use "../../Scss-vars/Colors.scss";
.WhatWeDo {
  .title {
    .number {
      color: #941a1a;
      font-weight: bold;
    }
  }
  .smale-title {
    font-size: 18px;
    // color: Colors.$lightGray;
  }

  .image span {
    font-size: 15px;
    color: Colors.$lightGray;
    margin-top: 2rem;
  }
  .image p {
    font-size: 15px;
    color: Colors.$lightGray;
  }
}
.card-section {
  .card {
    transition: Colors.$transition;
    border: none !important;
    .img {
      transition: Colors.$transition;
      height: 163px;
    }
    .card-title {
      font-size: 27px;
      font-weight: bold;
      @media (max-width: 320px) {
        font-size: 25px;
      }
    }
    .content-wrap {
      transition: Colors.$transition;
      position: relative;
      height: 100px;
      overflow: hidden;
      .card-text {
        transition: Colors.$transition;
        font-size: 14px;
        // position: absolute;
        // bottom: -110px;
        margin-top: 15px;
        display: none;
        @media (max-width: 320px) {
          font-size: 12px;
        }
      }
      .card-text-hover {
        font-size: 13px;
        color: #000;
      }
    }

    &:hover {
      background-color: Colors.$mainColor;
      color: Colors.$white;
      transform: scale(1.03);
      .card-text {
        transition: Colors.$transition;
        // animation: textAnimation 0.9s;
        // bottom: -15px;
        display: block;
      }
      .card-text-hover {
        display: none;
      }
    }
  }
}
@keyframes textAnimation {
  0% {
    opacity: 0;
    bottom: -50px;
  }
  100% {
    opacity: 1;
    bottom: -15px;
  }
}
