@use "../../Scss-vars/Colors.scss";
.contact-us {
  margin-bottom: 4rem !important;
  overflow: hidden;
  .getInTouch {
    margin-top: 5rem !important;
    .title {
      font-weight: bold;
      font-size: 50px;
    }
    label {
      color: Colors.$lightGray;
    }
    .number {
      font-size: 19px;
      color: Colors.$lightGray;
    }
    .address {
      font-size: 19px;
      color: Colors.$lightGray;
      .icon {
        font-size: 25px;
      }
    }
    .social a {
      color: Colors.$lightGray;
      font-size: 33px;
    }
    .form {
      transition: Colors.$transition;
      .form-control {
        min-height: 50px;
        &:hover {
          border: 1px solid #a6b0ba;
        }
        &:focus {
          border: 1px solod gray;
          box-shadow: none;
        }
      }
      .error {
        transition: Colors.$transition;
        color: rgb(227, 36, 36);
        font-size: 14px;
      }
      .btn {
        background-color: Colors.$mainColor;
        color: Colors.$white;
        &:hover {
          background-color: #881414;
        }
      }
    }
  }
}
