@use "../../Scss-vars/Colors.scss";

.DefaultView {
  .img-wrapper {
    width: 47px;
  }
  h1 {
    font-size: 40px;
    font-weight: bold;
  }
  h6 {
    font-size: 14px;
    font-weight: 500;
    color: Colors.$lightGray;
  }
  .card {
    border: none !important;
    transition: Colors.$transition;
    .card-body {
      .card-title {
        font-size: 21px;
        font-weight: bold;
      }
      .card-text {
        font-size: 12px;
      }
      .icon {
        font-size: 25px;
        font-weight: bolder;
        margin-bottom: 0;
      }
      .type {
        font-size: 13px;
        font-weight: 600;
      }
      .card-link-wrap {
        position: relative;
        height: 24px;
        // overflow: hidden;
        .card-link {
          // position: absolute;
          // left: 50%;
          // bottom: -20px;
          // transform: translateX(-50%);
          // transition: Colors.$transition;
          // overflow: hidden;
          // color: #fff;
          span {
            font-size: 20px;
          }
          display: none !important;
        }
      }
    }
    &:hover {
      transform: scale(1.03);
      .test {
        align-items: flex-end !important;
      }
      a {
        color: Colors.$white;
      }
      background-color: Colors.$mainColor;
      color: Colors.$white;
      .card-link-wrap {
        .card-link {
          transition: Colors.$transition;
          display: flex !important;
          // animation: textAnimations 0.9s;
          // bottom: 0;
        }
      }
    }
  }
}

@keyframes textAnimations {
  0% {
    opacity: 0;
    bottom: -30px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
