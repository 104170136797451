@use "../../Scss-vars/Colors.scss";
.ImpactGame {
  .title {
    .number {
      color: #941a1a;
    }
  }
  .title2 {
    font-size: 20px;
    font-weight: bold;
  }
  .maintitle {
    font-size: 65px;
    font-weight: bold;
  }
  .midparagraph {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .midparagraph2 {
    font-size: 15px;
    text-align: center;
  }
  .smale-title {
    font-size: 18px;
    color: Colors.$lightGray;
  }

  .image span {
    font-size: 15px;
    color: Colors.$lightGray;
  }
  .image p {
    font-size: 15px;
    color: Colors.$lightGray;
  }

  .card {
    border: none !important;
    transition: Colors.$transition;
    .card-body {
      .card-title {
        font-size: 21px;
        font-weight: bold;
      }
      .card-text {
        font-size: 12px;
      }
      .icon {
        font-size: 25px;
        font-weight: bolder;
        margin-bottom: 0;
      }
      .type {
        font-size: 13px;
        font-weight: 600;
      }
      .card-link-wrap {
        position: relative;
        height: 24px;
        overflow: hidden;
        .card-link {
          // position: absolute;
          // left: 50%;
          // bottom: -20px;
          // transform: translateX(-50%);
          // transition: Colors.$transition;
          // overflow: hidden;
          // color: #fff;
          span {
            font-size: 20px;
          }
          display: none !important;
        }
      }
    }
    &:hover {
      background-color: Colors.$mainColor;
      color: Colors.$white !important;
      transform: scale(1.03);

      a {
        color: Colors.$white !important;
      }
      .card-link-wrap {
        .card-link {
          transition: Colors.$transition;
          // animation: textAnimationsImpact 0.9s;
          // bottom: -5px;
          display: flex !important;
        }
      }
    }
  }
}

@keyframes textAnimationsImpact {
  0% {
    opacity: 0;
    bottom: -30px;
  }
  100% {
    opacity: 1;
    bottom: -5px;
  }
}
