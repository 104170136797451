@use "./Scss-vars/Colors.scss";
@use "./Scss-vars/media.scss";
.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.top-card-link {
  text-decoration: none;
  color: #000;
}
.toasts {
  transition: Colors.$transition;
  position: absolute;
  top: 15%;
  right: 6%;
  background-color: rgb(103, 200, 103);
  border-radius: 3px;
  min-width: 300px;
  color: #fff;
  overflow: hidden;
  height: 0px;
}
.toasts-error {
  transition: Colors.$transition;
  position: absolute;
  top: 15%;
  right: 6%;
  background-color: red;
  border-radius: 3px;
  min-width: 300px;
  color: #fff;
  overflow: hidden;
  height: 0px;
}
