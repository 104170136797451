// 141414 dark
@use "../../Scss-vars/Colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;1,900&display=swap");
.container-fluid {
  .home {
    position: relative;
    background-color: #141414;
    color: Colors.$white;
    background-image: url("../../assets/Home/largLogo.png");
    background-size: 700px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    .home-row {
      display: flex;
      .content {
        margin-top: 30px !important;
        .text {
          .title {
            font-weight: bold;
          }
          .smale-title {
            font-weight: 500;
            font-size: 19px;
            margin-top: 22px;
          }
        }
        .caption {
          color: #c7bcbc;
        }
        .arrow-down {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          animation-name: arrowUpDown;
          animation-duration: 3s;
          animation-iteration-count: infinite;
        }
      }
    }
  }
  .what-we-do {
    @media (max-width: 768px) {
      text-align: center;
    }

    .second-title {
      font-weight: 600;
      font-size: 30px;
    }
    .smale-title {
      font-size: 17px;
      color: Colors.$lightGray;
    }
    .know-more a {
      color: Colors.$mainColor;
      text-decoration: none;
      font-size: 17px;
      font-weight: bold;
      & span {
        font-size: 20px;
        font-weight: bolder;
      }
    }
  }
  .getIn {
    p {
      font-weight: bold;
      font-size: 45px;
    }
    .btn {
      background-color: Colors.$mainColor;
      color: Colors.$white;
      width: 160px;
      &:hover {
        background-color: #881414;
      }
    }
  }
}

@keyframes arrowUpDown {
  30% {
    margin-top: 20px;
  }
}
.home-carousel {
  .carousel-caption {
    bottom: -1.7rem;
    h3 {
      font-size: 15px;
    }
  }
  .carousel-control-prev {
    left: -20px;
  }
  .carousel-control-next {
    right: -20px;
  }
}

.H-smale-title {
  color: #757474 !important;
}
