@media (min-width: 1920px) {
  .container {
    max-width: 1580px;
  }
}

@media (max-width: 3000px) {
  .home-responsive-title {
    font-size: 85px;
    line-height: 85px;
  }
  .responsive-title {
    font-size: 75px;
  }
  .second-responsive-title {
    font-size: 45px;
  }
  .smale-title {
    width: 85%;
  }
  .get-inTouch-image {
    margin-left: 10rem;
  }
}
@media (max-width: 1024px) {
  .home-responsive-title {
    font-size: 75px;
    line-height: 85px;
    text-align: center;
  }
  .home-smale-title {
    text-align: center;
  }
  .responsive-title {
    font-size: 65px;
  }
  .second-responsive-title {
    font-size: 36px;
  }
  .responsive-header-link {
    justify-content: start !important;
  }
  .smale-title {
    width: 95%;
  }
  .get-inTouch-image {
    margin-left: 8rem;
  }
}

@media (max-width: 768px) {
  .home {
    background-size: 600px !important;
  }
  .home-responsive-title {
    text-align: center;
    line-height: 85px;
  }
  .responsive-title {
    text-align: center;
  }
  .second-responsive-title {
    font-size: 35px;
    text-align: center;
    justify-content: center;
  }
  .home-smale-title {
    text-align: center;
  }
  .home-smale-title {
    text-align: center;
  }
  .smale-title {
    text-align: center;
    width: 100%;
  }
  .get-inTouch-image {
    margin-left: 9rem;
  }
}
@media (max-width: 425px) {
  .home {
    background-size: 400px !important;
  }
  .home-responsive-title {
    text-align: center;
    font-size: 50px;
    line-height: 45px;
  }
  .responsive-title {
    text-align: center;
    font-size: 50px;
  }
  .second-responsive-title {
    justify-content: center;
    text-align: center;
    font-size: 25px;
  }
  .get-inTouch-image {
    margin-left: 0;
  }
  .get-inTouch-text {
    font-size: 35px !important;
  }
}
@media (max-width: 320px) {
  .home {
    background-size: 300px !important;
  }
  .home-responsive-title {
    font-size: 42px;
  }
  .responsive-title {
    font-size: 42px;
  }
  .second-responsive-title {
    justify-content: center;
    font-size: 30px;
    text-align: center;
  }
}
