@use "../../Scss-vars/Colors.scss";
.footer {
  background-color: #2c2b2b;
  color: Colors.$white;
  .numAndAddress {
    font-size: 12px;
    .icon {
      font-size: 25px;
    }
  }
  .social a {
    color: Colors.$white;
    font-size: 35px;
  }
  .footer-logo {
    .name {
      font-size: 35px;
    }
  }
}
