@use "../../../Scss-vars/Colors.scss";

.single-work{
    .project-title{
        font-weight: bold;
    }
    .header{
        .partners{
            color: Colors.$mainColor;
            font-size: 15px;
            span.bold{
                font-weight: bold;
            }
        }
        .project-type{
            color: Colors.$lightGray;
            font-size: 14px;
            font-weight: 200;
            margin-top: 20px;
        }
    }
    .challenge{
        font-size: 13px;
        h5{
            font-weight: bold;
            margin-top: 20px;
        }
        
    }
    .technologies{
        h5{
            font-weight: bold;
            margin-top: 20px;
        }
        .icons{
            font-size: 14px;
        }
        
    }
    .visit{
        color: Colors.$white;
        text-decoration: none;
        background-color: Colors.$mainColor;
        padding: 6px 10px;
        border-radius: 5px;
    }
    .nextPro{
        color: Colors.$mainColor;
        text-decoration: none;
        h6{
            font-size: 18px;
        }
        span{
            font-size: 22px;
        }
    }
}