@use "./Scss-vars/Colors.scss";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

*,
html {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
  background-color: Colors.$app-background !important;
}
main {
  overflow-x: hidden;
}
